body{
  width: 100%;
  height: 100vh;
  background-image: url("./img/bckg.webp");
  background-position: center;
  background-size: cover;
}

.chat{
  width: 1100px;
  height: 800px;
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  box-shadow: 20px 20px 40px rgba(255, 255, 255, 0.06);
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.input-area{
  width: 100%;
  display: flex;
  justify-content: center;
}

.input{
  width: 90%;
  border: solid 2px rgba(255, 255, 255, 0.6);
  background-color: rgba(151, 255, 25, 0.14);
  height: 40px;
  border-radius: 8px;
  outline: none;
  font-family: 'Decterm';
  font-size: 22px;
  color: rgba(151, 255, 25, 1);
  padding-left: 20px;
  max-width: 970px;
  margin-top: 20px;
}

.message-area{
  width: 90%;
  height: 400px;
  background-color: rgba(151, 255, 25, 0.14);
  border: solid 2px rgba(255, 255, 255, 0.6);
  border-radius: 12px;
}

.env-logo img{
  width: 350px;
  margin-top: -58px;
}

@font-face {
  font-family: 'Decterm';
  src: url('./_decterm.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.env-logo{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 280px;
  background-image: url('./angel-back.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.message-area{
  font-family: 'Decterm';
  color: rgba(151, 255, 25, 1);
  font-size: 22px;
  text-shadow: 2px 2px 2px black;
  overflow: scroll;
  overflow-x: hidden;
}

.message {
  font-family: 'Decterm';
  font-size: 22px;
  text-shadow: 2px 2px 2px black;
  max-width: 100%;
  padding: 10px 15px;
  border-radius: 12px;
  word-wrap: break-word;
}

.user-message {
  align-self: flex-end;
  color: rgba(151, 255, 25, 1);
  text-align: right;
}

.angel-message {
  align-self: flex-start;
  color: rgba(151, 255, 25, 1);
  text-align: left;
}

.env-icons{
  position: absolute;
  display: flex;
  flex-direction: column;
  right: 55px;
  top: 60px;
}

.env-icons a{
  padding: 10px 0;
}

.message-area::-webkit-scrollbar {
  width: 8px; /* Narrow scrollbar width */
}

.message-area::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1); /* Light, subtle background */
  border-radius: 10px;
}

.message-area::-webkit-scrollbar-thumb {
  background: rgba(151, 255, 25, 0.6); /* Bright green scrollbar thumb */
  border-radius: 10px;
}

.message-area::-webkit-scrollbar-thumb:hover {
  background: rgba(151, 255, 25, 0.8); /* Slightly darker green on hover */
}

/* Minimalist scrollbar for Firefox */
.message-area {
  scrollbar-width: thin;
  scrollbar-color: rgba(151, 255, 25, 0.6) rgba(255, 255, 255, 0.1);
}

.tokenCa{
  display: flex;
  color: white; 
  width: 100px;
  overflow: hidden;
}

.envCa{
  display: flex;
  color: white;
  width: 165px;
  justify-content: space-between;
  border: solid 1px rgba(255, 255, 255, 0.4);
  border-radius: 3px;
  padding: 2px 5px;
  position: absolute;
  top: 10px;
  right: 20px;
}

.ponto{
  margin-left: -5px;
}

.copy{
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.ic{
  transition: 0.3s;
  cursor: pointer;
}

.ic:hover{
  transform: scale(1.1);
  transition: 0.3s;
}

.tokenName{
  position: absolute;
  font-family: 'Decterm';
  color: rgba(151, 255, 25, 1);
  font-size: 48px;
  top: 10px;
  left: 20px;
}
.tokenCa{
  color: white;
}